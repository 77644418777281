.message-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
  border-left: 6px solid #007BFF;
}
.message-container h1 {
  color: #007BFF;
  font-family: 'Nunito', sans-serif;
  font-size: 32px;
  margin-bottom: 20px;
}
.message-container p {
  color: #333333;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.6;
}
.important {
  color: #007BFF;
}