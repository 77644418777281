.navbar4-container {
  width: 100%;
  height: fit-content;
  display: flex;
  position: fixed;
  justify-content: center;
  background: linear-gradient(90deg, rgba(188,215,248,1) 0%, rgba(158,201,253,1) 35%, rgba(117,175,245,1) 100%);
  /* background: linear-gradient(90deg, rgba(180,212,250,1) 0%, rgba(135,187,250,1) 35%, rgba(92,160,242,1) 100%); */
  box-shadow: 0px 1px 2px 2px #e6eef733;
  z-index: 100;
  transition: top 0.5s;
}
.navbar4-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.navbar4-image1 {
  height: 3rem;
}
.navbar4-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar4-links {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}
.navbar4-buttons {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
}
.navbar4-burger-menu {
  display: none;
}
.navbar4-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar4-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}
.navbar4-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar4-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar4-logo {
  height: 3rem;
}
.navbar4-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar4-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar4-links1 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.navbar4-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
}
.navbar4-root-class-name {
  width: 100%;
}
@media(max-width: 767px) {
  .navbar4-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar4-desktop-menu {
    display: none;
  }
  .navbar4-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .navbar4-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .navbar4-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
