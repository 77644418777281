.hero-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: hidden;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  margin-top: var(--dl-space-space-elevenunits);
  margin-bottom: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 992px) {
  .hero-container {
    margin-top: var(--dl-space-space-fiveunits);
    margin-bottom: var(--dl-space-space-sixunits);
  }  
}
.hero-max-width {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
}
.hero-column {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.hero-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.hero-text {
  text-align: left;
}
.hero-text1 {
  text-align: left;
}
.hero-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.hero-video {
  flex: 1;
  width: 50%;
  height: auto;
  box-shadow: 7px 7px 0px 0px #444444;
  aspect-ratio: 16/9;
  border-color: var(--dl-color-theme-neutral-dark);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
}
.hero-root-class-name {
  width: 1200px;
}
@media(max-width: 1200px) {
  .hero-root-class-name {
    flex: 1;
    width: 1200px;
  }
}
@media(max-width: 991px) {
  .hero-max-width {
    flex-direction: column;
  }
  .hero-text {
    text-align: center;
  }
  .hero-text1 {
    text-align: center;
  }
  .hero-actions {
    align-self: center;
    justify-content: flex-start;
  }
  .hero-video {
    width: 100%;
  }
  .hero-root-class-name {
    width: auto;
  }
}
@media(max-width: 767px) {
  .hero-video {
    height: auto;
  }
}
@media(max-width: 479px) {
  .hero-actions {
    align-self: center;
    flex-direction: column;
  }
  .hero-button {
    width: 100%;
  }
}
