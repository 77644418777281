.contact-form-contact5 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact-form-section-title {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-contact-info {
  gap: 16px;
  display: flex;
  padding: 8px 0;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-row {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form-row1 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.contact-form-row2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.contact-form-form {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.contact-form-input {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-text-input {
  align-self: stretch;
}
.contact-form-input1 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-text-input1 {
  width: 100%;
}
.contact-form-input2 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact-form-textarea {
  width: 100%;
}
.contact-form-root-class-name {
  width: 1200px;
}
@media(max-width: 991px) {
  .contact-form-max-width {
    flex-direction: column;
  }
  .contact-form-content {
    width: 100%;
  }
  .contact-form-form {
    width: 100%;
  }
  .contact-form-root-class-name {
    width: auto;
  }
}
